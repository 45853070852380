@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-notifications/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-react-inplace-editor/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import 'Index.scss';

html,
#root {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

.modal-msg{
    margin: 15px 0;
}

.modal-img{
    margin: 0 10px;
    position: absolute;
    top: 124px;
}
.modal-notes {
    display: block;
    font-size: 16px;
    color: #2b2b2b;
    padding-bottom: 15px;
}

.reject-template{
    .reject-question {
        margin: 20px 0;
        font-size: 16px;
    }
}

.date-picker {
    display: flex;
    align-items: center;
    button {
        background: black;
        border: 1px solid #003;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        color: white;
        margin: 0 20px;
    }
    label {
        width: 90px;
        color: black;
        margin-right: 20px;
       &#end-label{
           margin-left: 20px;
       }
    }
    .e-input-group {
        width: 250px !important;
       
    }
}
.deposit-date {
    display: flex;
    button {
        background: black;
        border: 1px solid #003;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        color: white;
        margin: 0 20px;
    }
}

.reject-reason{
    margin: 10px 0;
    label{
        display: block;
        font-size: 16px;
        color: #2b2b2b;
        padding-bottom: 15px;
    }
    input {
        width: 60%;
    }
}

.graph-btn{
    justify-content: space-between;
    display: flex;
    align-items: center;
    button {
        background: black;
        border: 1px solid #003;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        color: white;
    }
}
.bulk-corrections{
    margin-top: 10px;
    display: flex;
    gap: 16px;
    // justify-content: center;
    button {
        background: black;
        border: 1px solid #003;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        color: white;
        // al
    }

}
.deposit-details {
    justify-content: space-between;
    display: flex;
    align-items: center;
    label {
        color: black;
        font-size: 20px;

    }
    select {
        background: white;
        color: black;
        margin: 0 10px;
        font-size: 16px;
    }
    button {
        background: black;
        border: 1px solid #003;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        color: white;
    }
    .fileUploader{
        border: none;
    }
} 

.sticky-banner {
    padding: 20px 0;
    text-align: center;
    background: red;
    color: white;
}

.e-dialog .e-dlg-header-content+.e-dlg-content {
    padding-top: 0 !important;
}

.correction-response {
    .error-message {
        color: $reliant-red;
        margin: 10px;
        font-style: italic;
        font-size: 1em;
        font-weight: 500;
    }
    .success-message{
        color: green;
        margin: 10px;
        font-style: italic;
        font-size: 1em;
        font-weight: 500;
    }
}
.intent-grid,.future-payments-grid{
    position: relative;
    .serach-clear{
        position: absolute;
        z-index: 1;
        top: 13px;
        font-size: 10px;
        left: 260px;
        color: red;
        cursor: pointer;
    }
}

.bulk-corrections-grid{
    margin-top: 2%;
}

.intent-tag{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .intent-name{
        display: inline-block;
        margin: 0;
    }
    .complete-btn{
        background: black;
        border: 1px solid #003;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        border-radius: 4px;
        color: white;
    }
}

.complete-btn.disabled, .confirm-btn.disabled, .e-editbutton.disabled, .reject-icon.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.complete-btne.enable, .confirm-btn.enable {
    pointer-events:all;
    opacity: 1;
}

.intent-page {
   .e-toolbar-right{
    left: 20px !important;
   }
   .e-toolbar-left{
    left: 275px !important
   }
}
.summary-block{
    display: inline-block;
    .no-data{
        display: none;
    }
    .e-circle-info {
        position: absolute;
        right: -23px;
    }
    h3{
        position: relative;
    }
    .card {
        border: 1px solid;
        position: relative;
        margin: 10px;
        min-width: 70px;
        float: left;
        padding: 0 35px 0 15px;
        border-color: transparent;
        box-shadow: 0 3px 1px -2px grey, 0 2px 2px 0 black, 0 1px 5px 0 black;
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    span {
        &.edit-icon{
            padding: 0 10px;
            margin-left: 5px;
        }
    }
}
#amountInput, #accountInput{
    margin-left: 10px;
    width: 170px;
}
#submit-btn, #close-btn{
    height: 28px;
    margin: -4px 5px 0 5px;
}

#submit-btn {
    color: green;
    font-size: 16px;
}

#close-btn {
    color: red
}

.e-editable-action-buttons{
    margin-top: -39px !important;
    margin-left: 166px;
    right: unset !important;
}
.show{
    display: block
}
.hide {
    display: none
}

#accountEdit, #amountEdit {
    height: 16px;
    .e-clear-icon{
        display: none;
    }
}

.e-editable-overlay-icon{
    visibility: visible !important;
}

.e-btn-cancel{
    position: absolute
}

.e-cancel-icon{
    color: red !important
}

.e-save-icon{
    color: green !important
}

.error{
    color: red
}

.edit-icon{
    background-image: url('../assets/images/edit-solid.png');
    background-repeat: no-repeat;
    padding: 10px;
    border: none !important;
}
.rejected-icon {
    border: none !important;
    background: no-repeat;
    color: red;
    padding-left: 20px;
    background-image: url('../assets/images/cancel.png');
}
.save-icon{
    margin : 0 5px;
}
.close-icon{
    color: red;
}
.edit-icon-MessagePopup{
    background-image: url('../assets/images/edit-solid.png');
    background-repeat: no-repeat;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    // border: none !important;
}
.edit-form, .info-form {
    input {
        height: 10px;
        width: 200px;
    }
    .e-icons.e-edit{
        margin-left: 5px;
    }
    .deposit-field{
        margin-top: 8px;
    }
    .e-icons.e-circle-info{
        cursor: pointer;
    }
    .deposit-button{
        width: 100%;
        button{
            background: black;
            border: 1px solid #003;
            color: white;
            margin-top: 16px;
            margin-left: 85%;
        }
    }
}
.e-editable-open{
    display: none;
}

.save-icon, .close-icon {
    height: 35px;
    width: 35px;
    
    span {
        font-size: 17px;
    }
}

.in-valid{
    border: 1px solid red !important;
    // outlin;
}
.reject-icon{
    background-color: lightgrey !important;
    width: 100%;
    margin: 0 !important;
    border-radius: 20px;
    text-transform: none;
    letter-spacing: 0.5px;
    font-size: 14px;
}

.page-container {
    width: 100%;
    overflow-y: auto;
    padding: 25px;
    margin-top: $topbar-height;
    margin-left: $sidebar-width;
    &.close {
        margin-left:60px;
    }
}

.app {
    height: 100%;
}

.page-title {
    color: $royal-blue-dark;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0px;
    margin: 0;
}

.login-page{
    .layout-root{
        display: grid;
    }
}

.layout-root {
    display: flex;
    flex-direction: row;
    height: 100%;

    .top-bar {
        display: flex;
        justify-content: space-between;
    }

    .layout-first-column {
        z-index: 1;
        &:not(.sideBarOpen){
            .e-acrdn-header {
                padding: 0 15px;
            }
        }
        &.sideBarOpen{
            width: $sidebar-width;
            .e-acrdn-header {
                .e-acrdn-header-content {
                    .header-icon {
                        margin-right: 10px !important;
                    }
                }
            }
        }
        top:94px;
        height: 100%;
        width: 60px;       
        background-color: $blue-gray-medium;
        position: fixed;

        .e-accordion {
            border: 0px;
        }

        .e-acrdn-item {
            background-color: $blue-gray-medium;
            cursor: pointer;

            &:not(.e-expand-state) {
                height: 60px;
            }

            .e-toggle-icon {
                display: none;
            }

            .e-acrdn-header {
                display: flex;
                justify-content: center;
                color: $gray-medium-3;

                .e-acrdn-header-content {
                    width: 150px;

                    .header-icon {
                        margin-right: -15px;
                        vertical-align: middle;
                    }

                    h3 {
                        font-weight: 400;
                        text-align: left;
                        letter-spacing: 0px;
                        font-size: 15px;
                        vertical-align: middle;
                    }

                    &.e-active {
                        h3 {
                            font-weight: 700;
                            color: $gray-darkest;
                        }
                    }
                }
            }

            .sidenav-section-content {
                display: flex;
                flex-direction: column;
            }

            &.e-select.e-active,
            &.e-select.e-item-focus,
            &.e-select.e-expand-state {
                border: none;

                .e-acrdn-header {
                    background: $blue-gray-lighter;
                    border: none;
                }

                h3 {
                    font-weight: 700;
                    color: $gray-darkest;
                }
            }

            .e-acrdn-panel {
                .e-acrdn-content {
                    padding: 0;
                }

                a {
                    padding-left: 20%;
                    color: $gray-medium-3;
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: none;
                    text-align: left;
                    line-height: 1.7rem;
                }
            }
        }
    }
}

// For <input> elements where type="file", apply styles to the "Choose file" button that renders within the <input>
::-webkit-file-upload-button {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: $font-medium;
    font-weight: 500;
    width: fit-content;
    border-radius: 4px;

    background: $blue-gray-medium 0% 0%;
    border: 2px solid $blue-gray-medium;
    color: $royal-blue-dark;

    &:hover {
        background-color: $purple-light;
        border: 2px solid $royal-blue-dark;
    }
}
.future-payments-grid{
    .e-gridfooter {
        tfoot{
            tr{
                height: 40px;
            }
        }
    }
}
