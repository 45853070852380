@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';

@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css';
@import '../common/scrollbar.scss';
@import '../Index.scss';

$corner-radius: 8px;
$card-corner-radius: 4px;
$box-shadow: 0px 0px 5px #00000014;
$spacing-between-warnings: 2px;
$spacing-between-cards: 10px;

$page-container-padding: 25px;

$sidebar-width: 348px;
$sidebar-card-width: 288px;
$sidebar-padding-vert: 20px;
$sidebar-padding-horiz: ($sidebar-width - $sidebar-card-width) / 2;

$scrollbar-padding: 8px;

@mixin main-section-title {
    font-size: $font-large;
    font-weight: bold;
    color: $royal-blue-dark;
}
@mixin sidebar-title {
    font-size: $font-medium;
    font-weight: $font-weight-medium;
    color: $gray-dark;
}

.page-home {
    font-size: $font-medium;
    color: $royal-blue-dark;
    position: relative;
    height: 100%;
    button {
        &.reminder-button{
           border: none;
           background: none;
           span {
            color: transparent;
            position: absolute;
           }
           img {
            width: 20px
           }
        }
    }

    .not-complete-icon,.reminder-button {
        width: 90px;
        margin: 0 !important;
        border-radius: 20px;
        text-transform: none;
        letter-spacing: 0.5px;
        font-size: 14px;
    }

    .undo-verification-icon {
        width: 140px;
        margin: 0 !important;
        border-radius: 20px;
        text-transform: none;
        letter-spacing: 0.5px;
        font-size: 14px;
    }

    .home-heading{
        color: black;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 15px;
    }

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .main-window {
        margin-right: $sidebar-width - 25px;

        overflow-x: visible; // For hover animations that spill over

        overflow-y: scroll;
        height: 100%;
        padding: 20px;
        @include scrollbar($purple-medium, $purple-lighter);

        .main-window-pane {
            // Spacer bar between sections
            &:not(:last-child) {
                padding-bottom: 30px;
                margin-bottom: 33px;
                border-bottom: 1px solid $gray-light-3;
            }
        }
    }

    .sidebar {
        position: fixed;
        //z-index: -1; // Position back to not overrun top dropdown, except that hides clickable links
        right: 0px;
        top: 94px; // Position immediately below the search pane

        height: 100%;
        width: $sidebar-card-width + $scrollbar-width + $scrollbar-padding;

        padding: 0px ($sidebar-padding-horiz - $scrollbar-width - $scrollbar-padding)
            $sidebar-padding-vert $sidebar-padding-horiz;

        background-color: $blue-gray-medium;

        .title-container-sidebar {
            @include sidebar-title();

            display: inline-block;
            margin-bottom: 20px;
            padding-top: $sidebar-padding-vert;
        }

        // RETURNS

        p {
            font-size: $font-extra-small;
            color: $gray-dark;
            padding-left: 10px;
        }

        ul.view-all {
            @include scrollbar($purple-medium, $purple-lighter);

            overflow-y: auto;
            max-height: 390px;
            padding-right: $scrollbar-padding;
        }

        ul.view-some {
            // Account for missing space when scrollbar is not visible
            padding-right: $scrollbar-width + $scrollbar-padding;
        }

        li.card {
            background-color: $white;
            border: 1px solid $gray-lighter;
            border-radius: $card-corner-radius;

            // Padding-left is unique for each row; assigned elsewhere
            padding: 16px 17px 20px 0px;

            margin: 0px 0px $spacing-between-cards 0px;
            &:last-child {
                margin-bottom: 0px;
            }
            &:hover {
                cursor: pointer;
            }

            .top-flex-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-start;

                margin-bottom: 8px;

                font-size: $font-extra-small-modals;
                color: $gray-medium-2;

                .consumer-id {
                    display: inline;
                    text-align: left;
                    padding-left: 22px;
                    margin-right: 4px;
                }
                .return-reason {
                    display: inline;
                    text-align: right;
                    margin-left: 4px;

                    max-width: 55%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:hover {
                        white-space: normal;
                        overflow: visible;
                    }
                }
            }

            .bottom-flex-container {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-start;

                .consumer-name {
                    display: block;
                    text-align: left;

                    font-size: $font-medium;
                    font-weight: bold;
                    color: $royal-blue-dark;

                    margin-bottom: 5px;
                    margin-right: 4px;
                    padding-left: 21px;
                }
                .affiliate-id {
                    display: block;
                    text-align: left;

                    font-size: $font-small;
                    color: $gray-darker;

                    margin-right: 4px;
                    padding-left: 23px;
                }
                .date {
                    display: block;
                    text-align: right;

                    font-size: $font-extra-small-modals;
                    color: $gray-darker;

                    margin-left: 4px;
                    margin-bottom: 4px;
                }
                .amount {
                    display: block;
                    text-align: right;

                    font-size: $font-medium-large;
                    color: $gray-darker;

                    margin-left: 4px;
                }
                .phone-number {
                    display: block;
                    text-align: right;

                    font-size: $font-small;
                    color: $gray-darker;

                    margin-left: 4px;
                }
            }
        }
    }

    .row-flex-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-end;
    }

    // NEWS AND UPDATES VIEW

    .title-container-warnings {
        @include main-section-title();

        margin-bottom: (19px - $spacing-between-warnings);
        display: inline-block; // Required for margin to be displayed

        .date-picker {
            margin: 10px;
        }
    }
    .warning {
        font-size: $font-small;
        color: $gray-darker;
        margin-top: $spacing-between-warnings;
    }
    .red-text-button {
        font-size: $font-extra-small;
        color: $red;
        font-weight: bold;
        border: 0px;
        background-color: #ffffff00;

        &:hover {
            color: darken($red, 10%);
        }

        &:active {
            color: darken($red, 20%);
        }

        &:enabled {
            cursor: pointer;
        }
    }

    // COMPANY STATISTICS VIEW

    .title-container-stats {
        margin-bottom: 22px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between; // Push date range picker to far right
        align-items: flex-end;

        label {
            @include main-section-title();
            margin-right: 3px;
        }

        // Legend
        span {
            font-size: $font-extra-small-labels;
            margin-left: 15px;
        }

        .date-picker {
            margin-left: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end; // right
            align-items: flex-end;
        }
    }

    .statistics-row-flex-container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        .statistic-container {
            $statistic-spacing: 20px;

            // Prevent neighboring elements from moving on hover by setting a fixed height/width
            height: 50px;
            width: 135px;

            overflow-wrap: break-word;

            &:not(:last-child) {
                margin-right: $statistic-spacing;
            }

            // Core statistic view
            label {
                color: $black;
                font-size: $font-small;
            }
            img {
                padding-left: 5px;
            }
            .value {
                color: $royal-blue-dark;
                font-size: $font-extra-large-3;
            }

            .statistic-expanded {
                min-width: 120px;
                position: relative;
                z-index: 100;

                $border-width: 1px;
                $padding-sides: 14px;
                $padding-top: 8px;
                $padding-bottom: 7px;

                background-color: $gray-lightest-1;
                border: $border-width solid $gray-lighter;
                border-radius: $corner-radius;
                box-shadow: $box-shadow;

                padding: $padding-top $padding-sides $padding-bottom;
                margin: (-1 * $border-width - $padding-top) (-1 * $border-width - $padding-sides)
                    (-1 * $border-width - $padding-bottom);

                .details-container {
                    // These flexbox styles ensure the details remain the same width as the core statistic view
                    // If the details need more space than is available, they will wrap to the next line
                    display: flex;

                    .details {
                        flex-grow: 1;
                        width: 0;
                        overflow: visible;

                        label {
                            color: $gray-darker;
                            font-size: $font-extra-small-modals;
                        }
                        .industry-average {
                            font-size: $font-extra-large-2;
                            color: $royal-blue-dark;
                        }
                        .above-average-percent {
                            font-size: $font-extra-large-2;
                            color: $sky-blue-medium;
                        }
                    }
                }
            }
        }
    }

    // MY DASHBOARD VIEW

    .title-container-dashboard {
        margin-bottom: 22px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: left;
        align-items: center;

        .title {
            @include main-section-title();
            margin-right: 13px;
        }

        .dropdown-menu {
            right: auto;
            top: 25px;
            width: 210px;

            .dashboard-menu-options {
                @include scrollbar($purple-medium, $purple-lighter);
                overflow: auto;
                height: 386px;
            }

            .red-text-button {
                margin-bottom: 10px;
            }
        }
    }

    .dashboard-selection {
        display: flex;
    }
}
