@import 'Index.scss';

/********************************************** Fonts **********************************************/
* {
    font-family: $ubuntu;
}

/********************************************** Labels and inputs **********************************************/

label,
input,
textarea {
    text-align: left;
    letter-spacing: 0px;
    font-size: 1em;
    font-weight: 300;
}



  label {
    color: $purple-light;
    line-height: 18px;
}

@mixin input-styles {
    color: $royal-blue-dark;
    padding: 8px 13px;
    line-height: 18px;
    border: 1px solid $gray-lighter;
    border-radius: 4px;
    font-weight: 400;
}

input {
    @include input-styles;
}

textarea {
    color: $royal-blue-dark;
    padding: 8px 8px;
    border: 1px solid $gray-lighter;
    border-radius: 4px;
}

span.e-input-group.e-control-wrapper.e-mask:not(.e-success):not(.e-warning) {
    font: unset;
    border: unset !important;
    &::before,
    &::after {
        width: 0;
    }

    input.e-input.e-maskedtextbox {
        &,
        &:focus,
        &:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus) {
            @include input-styles;
            width: unset;
            outline: revert;
        }
    }
}

span.e-input-group.e-control-wrapper.e-mask {
    position: unset;
    width: unset;
    border: none;
}

input:focus,
textarea:focus {
    outline: none !important;
    border: 2px solid $royal-blue-dark;
}

/********************************************** Checkboxes **********************************************/

input[type='checkbox']:not(.e-checkselect):not(.e-checkselectall):not(.e-chk-hidden):not(.e-checkbox) {
    -webkit-appearance: none;
    background: $white 0% 0% no-repeat padding-box;
    border: 1px solid $gray-light-2;
    border-radius: 4px;
    opacity: 1;
    padding: 9px;
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    margin-left: 0;
    margin-right: 8px;
    cursor: pointer;

    &:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:checked {
        background: $white 0% 0% no-repeat padding-box;
        border: 1px solid $gray-light-2;
        border-radius: 4px;
        opacity: 1;

        &:after {
            content: '\2714';
            font-size: 14px;
            position: absolute;
            top: 0px;
            left: 3px;
            color: $royal-blue-dark;
            font-weight: 400;
        }
    }
}

input[type='checkbox']:disabled,
.disabled-checkbox {
    pointer-events: none;
    opacity: 0.5;
}

/********************************************** Text Elements **********************************************/

p {
    text-align: left;
    letter-spacing: 0px;
    font-size: 1em;
    font-weight: 400;
    color: $gray-darker;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $royal-blue-dark;
    font-weight: 700;
    text-align: left;
    letter-spacing: 0px;
}

/********************************************** Dividers **********************************************/

hr {
    height: 0px;
    border: 1px solid $gray-lightest-3;
    opacity: 1;
    width: 100%;
}

/********************************************** Dropdowns **********************************************/

select {
    background-color: $white;
    color: $royal-blue-dark;
    font-size: $font-extra-small;
    border-radius: 4px;
    border: 1px solid $gray-lighter;
    padding: 8px 13px;
    line-height: 18px;
    font-weight: 400;
}

select:focus {
    outline: none !important;
    border: 2px solid $royal-blue-dark;
}
