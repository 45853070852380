.reset-page.card{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .hide{
        display: none;
    }
    .error{
        color: red;
        margin-top: 5px;
    }
    .row{
        margin-top: 20px;
        position: relative;
       
        input{
            width: 300px;
            
        }
        span {
            position: absolute;
            top: 10px;
            right: 15px;
        }
        button {
            width: 150px;
            color: white;
            background-color: black;
            height: 35px;
            border-radius: 4px;
        }
    }
}