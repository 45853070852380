@import '../Index.scss';

.e-dialog { 
    background-color: $white;
    &.e-dlg-modal{
         max-height: 90% !important;
    }
   

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    .e-dlg-header-content {
        background-color: $royal-blue-dark;
        padding: 5px 15px !important;
        font-size: 1rem;

        .e-btn.e-flat.e-dlg-closeicon-btn {
            background-color: transparent;
            .e-btn-icon.e-icon-dlg-close
            {
                color: white;

                &:hover {
                    color: $gray-light-1;
                }
            }
        }
    }
    
    .e-dlg-header-content + .e-dlg-content {
        padding-top: 25px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 15px;
    }

    .e-dlg-content{
        color: $gray-darkest;
        font-size: 1rem;
    }

    .e-dlg-header {
        color: $white;
        font-weight: 400;
    }

    .e-btn.e-flat {
        background-color: $white;
        border: 1px solid $royal-blue-dark;
        color: $royal-blue-dark;

        &:hover {
            background-color: $gray-lightest-3;
        }

        &.e-primary {
            background-color: $royal-blue-dark;
            color: white;
            &:hover {
                opacity: 0.8; 
            }
        }
    }
}