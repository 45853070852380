@import '../Index.scss';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.top-bar-container {
    position: fixed;
    background-color: white;
    width: 100%;
    height: $topbar-height;
    hr {
        margin: 0;
    }

    .top-bar-layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 26px 26px 26px 0px;
        height: $searchbar-height; // Maintain same topbar height when searchbar is hidden
        .app-logo {
            float: left;
            justify-content: left;
            img {
                &:first-child{
                    float: left;
                   padding-top: 32px;
                   padding-left: 15px;
                   @media (max-width:1060px) {
                        margin-top: 5px;
                        float: left;
                        position: absolute;
                        padding: 0;
                   }
                }
                &:last-child{
                    padding: 30px 0px 26px 30px;
                    @media (max-width:1060px) {
                        padding: 0;
                        margin-top: -40px;
                        float: left;
                    }
                }
            }
        }
        .dropdown {
            float: right;
            position: relative;
        }

        .search-section {
            display: flex;
            align-items: center;
            color: $gray-medium-2;
            font-family: Arial, Helvetica, sans-serif;
        }
    }
}
