.login {
    width: 100%;
    display: flex;
    &.qrcode{
        align-items: center;
        flex-direction: column;
       }
    background-color: lightgrey;
    justify-content: center;
    align-items: flex-end;
    p {
        text-align: center;
        width: 300px;
        margin: 20px auto;
    }
    span {
        position: absolute;
        right: 15px;
        // @media screen and (min-width: 600px) and (max-width: 650px){
        //     right: -10px;   
        // }
        top: 10px;
        color: gray;
    }
    input {
        border-radius: 10px;
        width: 100%;
        // width: 300px;
    }
    >div {
        width: 100%;
    }
    .login-block{
        background-repeat: no-repeat;
        background-position-y: top;
        position: relative;
        height: 100%;
        img {
            width: 80%;
            position: absolute;
            right: 0;
            @media screen and (min-width: 650px) and (max-width: 880px) {
                width: 100%;
            }
            height: 100%;
            margin-top: -4px;
        }
        @media screen and (max-width: 650px) {
            display: none;
        }
    }
    .login-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        min-height: 600px;
    }
    .logo {
        margin: 20px auto 0;
    }
    .login-img {
        height: 85%;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    .login-error {
        text-align: center;
        color: red;
    }
    .login-forgot {
        display: block;
        text-align: center;
    }
    .row{
        display: flex;
        margin-bottom: 10px;
        position: relative;
        width: 50%;
        min-width: 225px;
        margin: 0 auto 10px;
        label {
            color: black;
            line-height: 18px;
            font-size: 22px;
        }
        &:first-child{
            input {
                margin-left: -10px;
            }
        }
    }
    .login-button {
        text-align: center;
        button {
            cursor: pointer;
            height: 40px;
            width: 150px;
            border-radius: 5px;
            background: black;
            color: white;
            font-size: 20px;
            margin-top: 20px;
        }
    }
}

.login-page{
    .login.card{
        position: relative;
    }
    .spinner-container{
        position: absolute;
    }
}