@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);

/********************************************** Font Definitions **********************************************/
$ubuntu: Ubuntu;

$font-extra-small-labels: 11px;
$font-extra-small-modals: 13px;
$font-extra-small: 14px;
$font-small: 15px;
$font-medium: 16px;
$font-medium-large: 19px;
$font-large: 20px;
$font-extra-large-1: 24px;
$font-extra-large-2: 26px;
$font-extra-large-3: 34px;

$font-weight-medium: 500;

/********************************************** Color Definitions **********************************************/
$white: #ffffff;
$black: #000000;

$blue-gray-lighter: #f5f8f8;
$blue-gray-light: #e9eef1;
$blue-gray-medium: #dbe3e8;
$blue-gray-medium-2: #cee0f1;

$sky-blue-medium: #3182d6;

$royal-blue-dark: #000033;
$royal-blue-darker: #00001a;

$gray-lightest-1: #f9f9f9;
$gray-lightest-2: #f3f3f3;
$gray-lightest-3: #e6e6e6;
$gray-lighter: #dddddd;
$gray-light-1: #bdbdbd;
$gray-light-2: #cccccc;
$gray-light-3: #e1e1e1;
$gray-light-translucent: rgba(0, 0, 0, 0.08);
$gray-medium-1: #8e8e8e;
$gray-medium-2: #8f8f8f;
$gray-medium-3: #707070;
$gray-dark: #404040;
$gray-darker: #494949;
$gray-darkest: #2b2b2b;

$red: #cc0000;
$reliant-red-lighter: #ffcccc;
$reliant-red: #ba0000;
$reliant-red-darker: #990000;
$reliant-faded-red: #d65858;
$reliant-red-translucent: rgba(186, 0, 0, 0.1);

$purple-lighter: #9393aa;
$purple-light: #696988;
$purple-medium: #4c4c70;

$orange-light: #e87537;

$black: #000000;

$green-success: #4caf50;

/********************************************** Size Definitions **********************************************/
$sidebar-width: 266px;
$topbar-height: 100px;
$searchbar-height: 40px;
