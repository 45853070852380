@import '../Index.scss';

.dropdown {
    cursor: pointer;
    position: relative;

    .dropdown-menu-button {
        display: flex;
        align-items: center;
        flex-direction: row;

        .reversed {
            flex-direction: row-reverse;
        }
    }

    img {
        margin: 5px;
    }

    .dropdown-menu {
        cursor: default;
        position: absolute;
        padding: 8px;
        width: 152px;
        right: 0px;
        top: 20px;
        background: white;
        border: 1px solid $gray-lighter;
        border-radius: 8px;
        font-size: 0.8em;
        font-weight: bolder;
        color: $royal-blue-dark;
        z-index: 3000;

        div {
            cursor: pointer;
            margin-bottom: 5px;
        }

        div:hover {
            color: $gray-darkest;
            font-weight: bold;
        }
    }
}
