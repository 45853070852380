@import '../Index.scss';

$scrollbar-width: 6px;
$scrollbar-corner-radius: 3px;

@mixin scrollbar($thumb-color, $track-color) {
    &::-webkit-scrollbar-track {
        border-radius: $scrollbar-corner-radius;
        background-color: $track-color;
    }

    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-corner-radius;
        background-color: $thumb-color;
    }
}
